.App {
  text-align: center;
}

.outline {
  border: solid 2px;
}

.black-background {
  color: rgb(255, 255, 255);
}

.box-margin {
  margin-top: 10px;
  margin-bottom: 25px;
}

.left-margin {
  margin-left: 25px;
}

#menu {
  margin: 10px;
}

.gray-text {
  color: rgb(83, 83, 83);
}

.transaction-card {
  border: solid 2px;
  border-color: rgb(51, 51, 51);
  margin: 5px;
  padding: 10px;
}

.box-width {
  width: 200px;
}

.accordion-width {
  width: 300px;
}

.detail {
  margin-top: 10px;
  font-size: 85%;
  white-space: pre-wrap;
}

#login-box {
  margin: 50px;
}

#login-button {
  text-align:right;
  margin-top: 25px;
}